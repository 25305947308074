.app-div {
    text-align: center;
    font-family: Arial;
    font-size: 14px;
  }

  .focus-div {
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    width: 90%;
    margin-top: 1rem;
    padding: 0.5rem;
    margin-left: auto;
    margin-right: auto; 
  }
  
  .focus-header {
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    margin: 25px;
    float: center;
    width: 75%;
    min-height: 100%;
    margin-top: 1rem;
    padding: 0.5rem;
    margin-left: auto;
    margin-right: auto;
  }

  .focus-invisible-array {
    width: 100%;
    clear: both;
    margin-left: auto;
    margin-right: auto;
    position: static;
    padding: 0rem;
    display: flex;
    flex-wrap: wrap;
  }

  .focus-container {
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    margin: 1px;
    float: center;
    width: 25%;
    min-height: 100%;
    margin-top: 1rem;
    padding: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
  }

  .focus-container label {
    width: 100%;
    padding: 2.5px 2.5px;
    margin: 0;
    box-sizing: border-box;
    border: 5%;
    border-radius: 4px;
    clear: both;
  }

  .focus-container select {
    background:rgb(255, 255, 255);
    width: 100%;
    padding: 2.5px 2.5px;
    margin: 0;
    box-sizing: border-box;
    border: 5%;
    border-radius: 4px;
    clear: both;
  }

  .focus-array-item {
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    margin: 25px;
    float: left;
    width: 25%;
    min-height: 100%;
    margin-top: 1rem;
    padding: 0.5rem;
    flex-wrap: wrap;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .focus-stat-item {
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    margin: 25px;
    float: left;
    width: 18%;
    min-height: 100%;
    margin-top: 1rem;
    padding: 0.5rem;
    flex-wrap: wrap;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .focus-login {
    width: 50%;
    clear: both;
    margin-left: auto;
    margin-right: auto;
    float: center;
    min-height: 100%;
    margin-top: 1rem;
    padding: 0.5rem;
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
  }
  
  .focus-login input {
      background:rgb(255, 255, 255);
      width: 75%;
      padding: 2.5px 2.5px;
      margin: 0;
      box-sizing: border-box;
      border: 5%;
      border-radius: 4px;
      clear: both;
  }

  .focus-login select {
    background:rgb(255, 255, 255);
    width: 75%;
    padding: 2.5px 2.5px;
    margin: 0;
    box-sizing: border-box;
    border: 5%;
    border-radius: 4px;
    clear: both;
}

.focus-edit {
  width: 65%;
  clear: both;
  margin-left: auto;
  margin-right: auto;
  float: center;
  min-height: 100%;
  margin-top: 1rem;
  padding: 0.5rem;
  border-radius: 2rem;
  z-index: auto;
  backdrop-filter: blur(2rem);
}

.focus-edit-box {
  width: 100%;
    clear: both;
    margin-left: auto;
    margin-right: auto;
    float: center;
    min-height: 100%;
    margin-top: 1rem;
    padding: 0.5rem;
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
}

.focus-edit input {
    background:rgb(255, 255, 255);
    width: 75%;
    padding: 2.5px 2.5px;
    margin: 0;
    box-sizing: border-box;
    border: 5%;
    border-radius: 4px;
    clear: both;
}

.focus-edit select {
  background:rgb(255, 255, 255);
  width: 75%;
  padding: 2.5px 2.5px;
  margin: 0;
  box-sizing: border-box;
  border: 5%;
  border-radius: 4px;
  clear: both;
}

  .dash-stats {
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    margin: 25px;
    float: left;
    width: 25%;
    min-height: 100%;
    margin-top: 1rem;
    padding: 0.5rem;
  }

  .dash-header {
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    margin: 25px;
    width: 25%;
    min-height: 100%;
    margin-top: 1rem;
    padding: 0.5rem;
    margin-left: auto;
    margin-right: auto;
  }

.dash-text {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.pass-validate p {
  color: red;
}

/* Popup style */
.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.box {
  position: relative;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}

.close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}

.focus-text {
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.35)
  );
  border-radius: 2rem;
  z-index: auto;
  backdrop-filter: blur(2rem);
  width: 85%;
  margin-top: 1rem;
  padding: 0.5rem;
  margin-left: auto;
  margin-right: auto; 
  text-align: justify;
  text-justify: inter-word;
}

.focus-text-small {
  border-radius: 2rem;
  z-index: auto;
  backdrop-filter: blur(2rem);
  width: 70%;
  margin-top: 1rem;
  padding: 0.5rem;
  margin-left: auto;
  margin-right: auto; 
  text-align: justify;
  text-justify: inter-word;
}

.focus-about {
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.35)
  );
  border-radius: 2rem;
  z-index: auto;
  backdrop-filter: blur(2rem);
  width: 85%;
  margin-top: 1rem;
  padding: 0.5rem;
  margin-left: auto;
  margin-right: auto;
}

.focus-container-small {
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.35)
  );
  border-radius: 2rem;
  z-index: auto;
  backdrop-filter: blur(2rem);
  margin: 1px;
  float: center;
  width: 15%;
  min-height: 100%;
  margin-top: 1rem;
  padding: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}

.focus-container-small label {
  width: 100%;
  padding: 2.5px 2.5px;
  margin: 0;
  box-sizing: border-box;
  border: 5%;
  border-radius: 4px;
  clear: both;
}

.focus-container-small select {
  background:rgb(255, 255, 255);
  width: 100%;
  padding: 2.5px 2.5px;
  margin: 0;
  box-sizing: border-box;
  border: 5%;
  border-radius: 4px;
  clear: both;
}

.pageNumbersDiv {
  border-radius: 0.5rem;
  z-index: auto;
  backdrop-filter: blur(2rem);
  width: 50%;
  margin-top: 0.5rem;
  padding: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.pageNumbers {
  margin-left: auto;
  margin-right: auto;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.pageNumbers li {
  padding: 8px;
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.35)
  );
  z-index: auto;
  backdrop-filter: blur(2rem);
  cursor: pointer;
}

.pageNumbers li.active {
  background-color: white;
  color: black;
}

.pageNumbers li button {
  border-radius: 10px;
  background-color: transparent;
  border: none;
  color: black;
  cursor: pointer;
}
.pageNumbers li button:focus {
  border-radius: 10px;
  outline: none;
}

.loadmore {
  padding: 1rem;
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.35)
  );
  z-index: auto;
  backdrop-filter: blur(2rem);
  border-radius: 10px;
  color: black;
  border: 1px solid white;
  cursor: pointer;
}

.focus-orange {
  color: orange;
}

.focus-cc {
  background: rgba(254, 255, 211, 0.5);
  border-radius: 2rem;
  z-index: auto;
  backdrop-filter: blur(2rem);
  width: 90%;
  margin-top: 1rem;
  padding: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.focus-ended {
  color: red;
}

.modal-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}
 
.box {
  position: relative;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}
 
.close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}

.focus-biz-pro {
  columns: 2;
  column-fill: balance;
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.35)
  );
  border-radius: 2rem;
  z-index: auto;
  backdrop-filter: blur(2rem);
  width: 90%;
  padding: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.focus-column {
  flex: 0%;
  padding: .5rem;
  height: 450px;
  max-width: 100%;
}

.focus-pro-card {
  height: 100%;
}

.focus-prod {
  columns: 2;
}

.focus-prod-left {
  width: 33.3%
}

.focus-prod-right {
  width: 66.7%
}

.focus-biz-pro-button {
  width: 95%;
  padding: 1rem;
  margin-left: auto;
  margin-right: auto;
}





.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}
.toggle-switch input[type="checkbox"] {
  display: none;
}
.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background-color: rgb(231, 222, 222);
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}
.toggle-switch .switch::before {
  position: absolute;
  content: "";
  left: 2px;
  top: 2px;
  width: 21px;
  height: 21px;
  background-color: rgb(172, 172, 172);
  border-radius: 50%;
  transition: transform 0.3s ease;
}
.toggle-switch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(25px);
  background-color: #2BA880;
}
.toggle-switch input[type="checkbox"]:checked + .switch {
  background-color: #8dd1a1;
}

.focus-rules-text {
  text-align: justify;
  text-justify: inter-word;
}

.focus-contrast {
  color: rgba(43, 168, 128);
}

.focus-contrast-rust {
  color: rgb(219, 105, 39);
}








































  .collection-sort {
  display: flex;
  flex-direction: column;
  }
  
  .img-array {
    align-items: center;
    border: none;
    border-radius: 10px;
    float: none;
  }

  .small-img {
    align-items: center;
    height: 50px;
    width: 50px;
    border: none;
    border-radius: 5px;
    float: none;
  }

  .medium-img {
    align-items: center;
    height: 150px;
    width: 150px;
    border: none;
    border-radius: 5px;
    float: none;
  }
  
  img {
    align-items: center;
    border: none;
    border-radius: 10px;
    float: none;
  }
  .img-array {
    align-items: center;
    border: none;
    border-radius: 10px;
    float: none;
  }

  .navimg {
    border: none;
    border-radius: none;
  }
  
  .container-array {
    width: 90%;
    clear: both;
    margin-left: auto;
    margin-right: auto;
    position: static;
    padding: 0rem;
    display: flex;
    flex-wrap: wrap;
  }
  
  .container-biz {
    margin: 25px;
    float: left;
    width: 25%;
    min-height: 100%;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    margin-top: 1rem;
    padding: 0.5rem;
  }
  
  .container-gway {
    margin: 25px;
    float: left;
    width: 25%;
    min-height: 100%;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    margin-top: 1rem;
    padding: 0.5rem;
  }
  
  .container-shop {
    margin: 25px;
    float: left;
    width: 28%;
    min-height: 100%;
    padding: 0 0 35px;
  }
  
  .container {
    width: 200px;
    clear: both;
    margin-left: auto;
    margin-right: auto;
  }
  
  .container input {
    width: 100%;
    padding: 5px 5px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 5%;
    border-radius: 4px;
    clear: both;
  }
  
  select {
    width: 100%;
    padding: 5px 5px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 5%;
    border-radius: 4px;
    clear: both;
  }
  
  .business-container {
    width: 99%;
    clear: both;
    margin-left: auto;
    margin-right: auto;
    position: static;
  }
  
  .business-title {
    position: fixed;
    left: 90px;
    padding-top: 10px;
    align-items: left;
  }
  
  .business-image {
    clear: both;
  }
  
  .title-name {
    clear: both;        
  }
  
  .biography {
    clear: both;
  }
  
  .business-details {
    clear: both;
  
  }
  
  .business-links {
    clear: both;
  }
  
  .modo-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 15px;
    display: flex;
  }
  
  .center-border {
    width: 50%;
    border-style: groove;
    clear: both;
    margin-left: auto;
    margin-right: auto;
    position: static;
  }
  
  .left-column {
    width: 50%;
    position: relative;
  }
  
  .left-column-below {
    position: relative;
    clear: both;
    align-items: left;
  }
   
  .right-column {
    width: 50%;
    margin-top: 60px;
  }
  
  .bottom-container {
    margin-top: 1rem;
    padding: 1rem;
    position: absolute;
    bottom: 0;
    width: 33%;
    padding-top: 0px;
    height: 83px; 
    align-items: center;
    left: 33%;
  }
  
  .left-column img {
    width: 37%;
    position: relative;
    left: 0;
    top: 0;
    transition: all 0.3s ease;
  }
  
  .left-column-gway-img {
    width: 50%;
    position: relative;
    left: 0;
    top: 0;
   
    transition: all 0.3s ease;
  }
   
  .left-column img.active {
    opacity: 1;
  }
  
  .product-description {
    border-bottom: 1px solid #E1E8EE;
    margin-bottom: 20px;
  }
  .product-description span {
    font-size: 12px;
    color: #358ED7;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: none;
  }
  .product-description h1 {
    font-weight: 300;
    font-size: 52px;
    color: #43484D;
    letter-spacing: -2px;
  }
  .product-description p {
    font-size: 16px;
    font-weight: 300;
    color: #86939E;
    line-height: 24px;
  }
  
  .search-container {
    width: 500px;
    clear: both;
    margin-left: auto;
    margin-right: auto;
  }
  
  .container-search {
    margin: 25px;
    float: left;
    width: 25%;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    margin-top: 1rem;
    padding: 0.5rem;
  }
  
  .search-container input {
    width: 90%;
    padding: 5px 5px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 5%;
    border-radius: 4px;
    clear: both;
  }
  
  .search-container-gway {
    width: 40%;
    clear: both;
    margin-left: auto;
    margin-right: auto;
    position: static;
    margin-top: 1rem;
    padding: 0.5rem;
    min-height: 100%;
    margin-top: 1rem;
    padding: 0.5rem;
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
  }
  
  .search-container-biz {
    width: 40%;
    clear: both;
    margin-left: auto;
    margin-right: auto;
    position: static;
    margin-top: 1rem;
    padding: 0.5rem;
    min-height: 100%;
    margin-top: 1rem;
    padding: 0.5rem;
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
  }
  
  .search-gway-title {
    width: 70%;
    clear: both;
    margin-left: auto;
    margin-right: auto;
    position: static;
   
  }
  
  .search-biz-title {
    width: 70%;
    clear: both;
    margin-left: auto;
    margin-right: auto;
    position: static;
   
  }
  
  .search-img-array {
    margin-top: 0.5rem;
    padding: 0.5rem;
    align-items: center;
    border: none;
    border-radius: 20px;
    float: none;
  }

  .home-stats {
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    margin: 25px;
    float: left;
    width: 25%;
    min-height: 100%;
    margin-top: 1rem;
    padding: 0.5rem;
  }
  
  .home-stat-box {
    width: 100%;
    clear: both;
    margin-left: auto;
    margin-right: auto;
    position: static;
    padding: 0rem;
    display: flex;
  }

  .home-giveaways {
    width: 100%;
    clear: both;
    margin-left: auto;
    margin-right: auto;
    position: static;
    padding: 0rem;
    display: flex;
  }
  
  .home-businesses {
    width: 100%;
    clear: both;
    margin-left: auto;
    margin-right: auto;
    position: static;
    padding: 0rem;
    display: flex;
  }

  .home-stat-container {
    width: 95%;
    clear: both;
    margin-left: auto;
    margin-right: auto;
    position: static;
    margin-top: 1rem;
    padding: 0.5rem;
    min-height: 100%;
    margin-top: 1rem;
    padding: 0.5rem;
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
  }

  .home-title-container {
    width: 95%;
    clear: both;
    margin-left: auto;
    margin-right: auto;
    position: static;
    margin-top: 1rem;
    padding: 0.5rem;
    min-height: 100%;
    margin-top: 1rem;
    padding: 0.5rem;
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
  }
  
  .home-container-gway {
    margin: 25px;
    float: left;
    width: 25%;
    min-height: 100%;
    padding: 0 0 35px;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    margin-top: 1rem;
    padding: 0.5rem;
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
  }
  
  .home-container-biz {
    margin: 25px;
    float: left;
    width: 25%;
    min-height: 100%;
    padding: 0 0 35px;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    margin-top: 1rem;
    padding: 0.5rem;
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
  }
  
  .feed-array {
    width: 90%;
    clear: both;
    margin-left: auto;
    margin-right: auto;
    position: static;
    padding: 0rem;
    display: flex;
    flex-wrap: wrap;
  }
  
  .search-results-filter {
    width: 50%;
    padding: 0rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
  }
  
  .chart-section {
    width: 78%;
    float: center;
    padding: 0rem;
    margin-left: auto;
    margin-right: auto;
    position: static;
  }
  
  main {
    min-height: 100vh;
    background: #ebebeb;
  }
  
  .glass-front {
     background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    margin: 25px;
    float: left;
    width: 25%;
    min-height: 100%;
    margin-top: 1rem;
    padding: 0.5rem;
  }
  
  .footer{
    padding: 1rem;
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.8)
    );
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-top: 0.5px;
    height: 30px; 
  }
  
  .home-img {
    align-items: center;
    height: 150px;
    width: 150px;
    border: none;
    border-radius: 5px;
    float: none;
  }
  
  .gway-list-glass {
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    width: 90%;
    margin-top: 1rem;
    padding: 0.5rem;
    margin-left: auto;
    margin-right: auto;
  }
  
  .gway-list-form {
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    margin: 25px;
    float: center;
    width: 90%;
    min-height: 100%;
    margin-top: 1rem;
    padding: 0.5rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
  }

  .container-filter {
       background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    margin: 25px;
    float: center;
    width: 90%;
    min-height: 100%;
    margin-top: 1rem;
    padding: 0.5rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
  }

  .glass-header {
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    margin: 25px;
    float: center;
    width: 90%;
    min-height: 100%;
    margin-top: 1rem;
    padding: 0.5rem;
    margin-left: auto;
    margin-right: auto;
  }
  
  .glass-container-array {
    width: 99%;
    clear: both;
    margin-left: auto;
    margin-right: auto;
    position: static;
    padding: 0rem;
    display: flex;
    flex-wrap: wrap;
  }
  
  .glass-container-gway {
    margin: 25px;
    float: left;
    width: 25%;
    min-height: 100%;
    margin-top: 1rem;
    padding: 0.5rem;
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
  }
  
  .spon-dash {
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    width: 90%;
    margin-top: 1rem;
    padding: 0.5rem;
    margin-left: auto;
    margin-right: auto;
  }
  
  .glass-feed {
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    width: 90%;
    margin-top: 1rem;
    padding: 0.5rem;
    margin-left: auto;
    margin-right: auto;
  }
  
  .glass-chart {
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    width: 90%;
    margin-top: 1rem;
    padding: 0.5rem;
    margin-left: auto;
    margin-right: auto;
  }

  .dash-glass-chart {
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    width: 90%;
    margin-top: 1rem;
    padding: 0.5rem;
    margin-left: auto;
    margin-right: auto;
    align-content: center;
  }

  .glass-chart-section {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }
  
  .glass-container-feed {
    margin: 20px;
    float: left;
    width: 26%;
    min-height: 100%;
    margin-top: 1rem;
    padding: 0.5rem;
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
  }
  
  .glass-login {
    width: 250px;
    clear: both;
    margin-left: auto;
    margin-right: auto;
    float: center;
    width: 26%;
    min-height: 100%;
    margin-top: 1rem;
    padding: 0.5rem;
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
  }
  
  .glass-login input {
    width: 100%;
    padding: 5px 5px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 5%;
    border-radius: 4px;
    clear: both;
  }
  
  .signup-agree {
    margin-left: auto;
    padding: 1rem;
  }
  
  .glass-account-edit {
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    width: 90%;
    margin-top: 1rem;
    padding: 0.5rem;
    margin-left: auto;
    margin-right: auto;
  }
  
  .glass-shop {
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    width: 90%;
    margin-top: 1rem;
    padding: 0.5rem;
    margin-left: auto;
    margin-right: auto;
  }
  
  .glass-business {
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    width: 85%;
    margin-top: 1rem;
    padding: 0.5rem;
    margin-left: auto;
    margin-right: auto;
  }
  
  .glass-biz-link {
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    width: 35%;
    margin-top: 1rem;
    padding: 0.5rem;
    margin-left: auto;
    margin-right: auto;
  }
  
  .glass-giveaway {
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    width: 85%;
    margin-top: 1rem;
    padding: 0.5rem;
    margin-left: auto;
    margin-right: auto;
  }
  
  .glass-giveaway-details {
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    width: 80%;
    margin-top: 1rem;
    padding: 0.5rem;
    margin-left: auto;
    margin-right: auto;
  }
  
  .giveaway-countdown {
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    width: 80%;
    margin-top: 1rem;
    padding: 0.5rem;
    margin-left: auto;
    margin-right: auto;
  }
  
  .giveaway-button {
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    width: 35%;
    margin-top: 1rem;
    padding: 0.5rem;
    margin-left: auto;
    margin-right: auto;
  }
  
  .additional-giveaway-info {
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    width: 80%;
    margin-top: 1rem;
    padding: 0.5rem;
    margin-left: auto;
    margin-right: auto;
  }
  
  .glass-product {
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    width: 85%;
    margin-top: 1rem;
    padding: 0.5rem;
    margin-left: auto;
    margin-right: auto;
  }
  
  .glass-search-container {
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    width: 95%;
    margin-top: 1rem;
    padding: 0.5rem;
    margin-left: auto;
    margin-right: auto;
  }
  
  .glass-search-container input {
    width: 50%;
    padding: 5px 5px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 5%;
    border-radius: 4px;
    clear: both;
  }
  
  .glass-search-page {
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    width: 85%;
    margin-top: 1rem;
    padding: 0.5rem;
    margin-left: auto;
    margin-right: auto;
  }
  
  .glass-image-box {
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    width: 50%;
    margin-top: 1rem;
    padding: 0.5rem;
    margin-left: auto;
    margin-right: auto;
  }
  
  .glass-dropdown-box {
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    margin: 25px;
    float: center;
    width: 35%;
    min-height: 100%;
    margin-top: 1rem;
    padding: 0.5rem;
    margin-left: auto;
    margin-right: auto;
  }
  
  .glass-sort-button {
    font-weight: bold;
  
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 0.5rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    width: 50%;
    margin-top: 0.5rem;
    padding: 0.5rem;
    margin-left: auto;
    margin-right: auto;
  }
  
  .loading-spinner {
    width: 3rem;
    height: 3rem;
    display: inline-block;
    border: 30px solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    border-top-color: rgba(43, 168, 128, 0.5);
    margin-bottom: 10rem;
    animation: 0.5s spin infinite ease-in-out;
  }
  
  @keyframes spin {
    to{
      transform: rotate(360deg);
    }
  }
  
  .glass-mail-item {
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    width: 50%;
    margin-top: 1rem;
    padding: 0.5rem;
    margin-left: auto;
    margin-right: auto;
  }
  
  .pagination {
    border-radius: 0.5rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    width: 30%;
    margin-top: 0.5rem;
    padding: 0.5rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  
  .page-link {
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    z-index: auto;
    backdrop-filter: blur(2rem);
    text-decoration: none;
    margin-top: 1rem;
    padding: 0.5rem;
    font-weight: bold;
  }
  
  .pageNumbersDiv-old {
    border-radius: 0.5rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    width: 90%;
    margin-top: 0.5rem;
    padding: 0.5rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  
  .pageNumbers-old {
    list-style: none;
    display: flex;
  }
  
  .pageNumbers-old li {
    font-weight: bold;
    padding: 10px;
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    z-index: auto;
    backdrop-filter: blur(2rem);
    cursor: pointer;
  }
  
  .pageNumbers-old li.active {
    background-color: white;
    color: black;
  }
  
  .pageNumbers-old li button {
    background-color: transparent;
    border: none;
    color: black;
    font-size: 1rem;
    cursor: pointer;
  }
  .pageNumbers-old li button:focus {
    outline: none;
  }
  
  .loadmore-old {
    padding: 1rem;
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    z-index: auto;
    backdrop-filter: blur(2rem);
  
    color: black;
    border: 1px solid white;
    cursor: pointer;
  }
  
  .glass-cc {
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    width: 40%;
    margin-top: 1rem;
    padding: 1rem;
    margin-left: auto;
    margin-right: auto;
  }
  
  .loading-spinner-processing {
    width: 3rem;
    height: 3rem;
    display: inline-block;
    margin-bottom: 10rem;
    border: 16px solid #ece9e9e5; /* Light grey */
    border-top: 16px solid #2BA880;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes process {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .glass-form-box {
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.35)
    );
    border-radius: 2rem;
    z-index: auto;
    backdrop-filter: blur(2rem);
    width: 100%;
    margin-top: 1rem;
    padding: 0.5rem;
    margin-left: auto;
    margin-right: auto;
  }
  
  .tiny-loading-spinner-processing {
    width: 1rem;
    height: 1rem;
    display: inline-block;
    margin-bottom: 4rem;
    border: 4.5px solid #ece9e9e5; /* Light grey */
    border-top: 4.5px solid #2BA880; /* Blue */
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  .spontoolbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(
        to bottom,
        rgba(78, 78, 78),
        rgba(0, 0, 0)
      );
    height: 44px;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    font-family: Arial, Helvetica, sans-serif;
    z-index: 999;
}

.spontoolbar__navigation {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 1rem;
}

.spontoolbar__logo {
    margin-left: 1rem;
    margin-top: 0.5rem;
}

.spontoolbar__logo a {
    color: white;
    text-decoration: none;
    font-size: 1.5rem;
}

.spacer {
    flex: 1;
}

.emoji {
    margin-right: 1rem;
    margin-top: 0.5rem;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    text-decoration: none;
    justify-content: space-between;
    width: 5%;
}

.spontoolbar_navigation-items {
 text-decoration: none;
}

.spontoolbar_navigation-items ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.spontoolbar_navigation-items li {
    padding: 0 0.5rem;
    display: flex;
    flex-direction: row;
}

.spontoolbar_navigation-items a {
    color: white;
    text-decoration: none;
}

.spontoolbar_navigation-items a:hover,
.spontoolbar_navigation-items a:active {
    opacity: .5;
}

.toolbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(43, 168, 128);
    height: 44px;
    font-family: Arial, Helvetica, sans-serif;
    z-index: 999;
}

.toolbar-ent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255);
  height: 44px;
  font-family: Arial, Helvetica, sans-serif;
  z-index: 999;
}

.toolbar__navigation {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 1rem;
}

.toolbar__logo {
    margin-left: 1rem;
    margin-top: 0.5rem;
}

.toolbar__logo a {
    color: white;
    text-decoration: none;
    font-size: 1.5rem;
}

.toolbar_navigation-items ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.toolbar_navigation-items li {
    padding: 0 0.5rem;
    display: flex;
    flex-direction: row;
}

.toolbar_navigation-items a {
    color: black;
    text-decoration: none;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
    color: rgb(43, 168, 128);
}

.toolbar__logo a:hover,
.toolbar__logo a:active {
    color: rgb(43, 168, 128);
}

.search-container {
    width: 500px;
    clear: both;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width: 768px) {
    .navsearchbox {
      visibility: hidden;
    }

    .no-mobile {
      visibility: hidden;
    }

    .spontoolbar_navigation-items {
      width: 300px;
    clear: both;
    margin-left: auto;
    margin-right: auto;
     }

     .emoji {
      width: 10px;
      clear: both;
      margin-left: auto;
      margin-right: auto;
    }
  }
  
  .navsearchbox {
    margin: 25px;
    float: left;
    width: 50%;
  }
  
  .navsearchbox input {
    width: 90%;
    padding: 5px 5px;
    margin: 5px 0;
    box-sizing: border-box;
    border: 5%;
    border-radius: 30px;
    clear: both;
  }

  .navsearchbox button {
    margin-left: 2px;
    margin-right: 2px;
    width: 8%;
    padding: 5px 5px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 3%;
    border-radius: 3px;
    clear: both;
  }

  .search-link {
    z-index: 10;
    padding-left: 0 
  }

  .search-spinbox {
    z-index: 10;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    position: relative;
  }

  .search-result-link {
    z-index: 10;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    position: relative;
    padding-left: 0 
  }

  .search-result-link:hover {
    opacity: .5;
}

.more-results:hover {
  opacity: .5;
}

.search-all-around:hover {
  background-color: #8dd1a1;
}

  .search-result-image {
    z-index: 10;
    margin-left: auto;
  }

  table {
    border-spacing: 0;
    border: 1px solid #ededed;
  }
  table tr:last-child td {
    border-bottom: 0;
  }
  table th,
  table td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid #ededed;
    border-right: 1px solid #ededed;
    position: relative;
  }
  table th:last-child,
  table td:last-child {
    border-right: 0;
  }
  table tr:nth-child(even) {
    background-color: #fafafa;
  }
  
  table th::before {
    position: absolute;
    right: 15px;
    top: 16px;
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
  table th.sort-asc::before {
    border-bottom: 5px solid #22543d;
  }
  table th.sort-desc::before {
    border-top: 5px solid #22543d;
  }
  
  .App-tab {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
  .badge {
    background-color: #9ae6b4;
    color: #22543d;
    margin-right: 4px;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }

  tableinput {
    padding: 10px;
    margin-bottom: 20px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-shadow: none;
  }
  .pagination {
    padding: 0.5rem;
  }

  .toggle-button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    width: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
  }

  .toggle-button:hover {
   opacity: .4;
  }
  
  .toggle-button:focus {
    outline: none;
  }
  
  .toggle-button__line {
    width: 30px;
    height: 3px;
    background: black;
  }

  .spon-toggle-button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    width: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
  }

  .spon-toggle-button:hover {
    opacity: .5;
   }
  
  .spon-toggle-button:focus {
    outline: none;
  }
  
  .spon-toggle-button__line {
    width: 30px;
    height: 3px;
    background: white;
  }

  .side-drawer {
    height: 100%;
    background: white;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 400px;
    z-index: 201;
    transform: translateX(-100%);
    transition: transform 0.2s ease-out;
    font-family: Arial, Helvetica, sans-serif;
  }

.side-drawer.open {
    transform: translateX(0);
  }
  
.side-drawer ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.side-drawer li {
    margin: 0.5rem 0;
}

.side-drawer a {
    color: rgb(0, 0, 0);
    text-decoration: none;
    font-size: 1.2rem;
}

.side-drawer a:hover,
.side-drawer a:active {
    color: rgb(148, 172, 154);
}

.sidebar-button {
    width: 100%;
    margin-left: 4.5rem;
    margin-right: auto;
  }

.sidebar-button h3 {
    color: #2BA880;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  .backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.3);
    z-index: 200;
}

.backdrop2 {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.3);
    z-index: 100;
}

.navinput {
    border: none;
    border-radius: 50px;
}

.form {
    display: flex;
    flex-direction: row;
  }
  .navsearch-field {
    width: 100%;
    padding: 10px 35px 10px 15px;
    border: none;
    border-radius: 100px;
    outline: none;
  }

.navsearchbox img {
    object-fit: cover;
  }

  .navsearch-button {
    background: transparent;
    border: none;
    outline: none;
    margin-left: -33px;
    visibility: hidden;
  }

.navform input{
   z-index: 101;
}

.navform input{
    z-index: 101;
 }

  .results-drop {
    position: absolute;
    display: inline-block;
    z-index: 101;
    width: 90%;
    text-align: left; 
    padding-left: 0 
  }

  .dropdown-content {
    position: relative;
    background:  rgba(255, 255, 255);
    width: 48%;
    overflow: auto;
    border: 1px solid #ddd;
    z-index: 101;
    text-align: left; 
    padding-left: 0;
  }

  .search-link-box {
    position: relative;
    top: 0;
    left: 0;
    width: 200px;
    height: 100%;
  }

  .search-link-box ul {
    text-decoration: none;
    padding-left: 20px;
  }

  .search-link-box li {
    text-decoration: none;
    padding-left: 20px;
  }

  .search-link-box p {
    text-decoration: none;
    padding-left: 20px;
  }

.emoji img:hover {
    opacity: .5;
}

.toolbar__logo img:hover {
    opacity: .5;
}

.spontoolbar__logo img:hover {
    opacity: .5;
}

.sidebar_logo {
  position: relative;
  padding: 5px 35px 5px 130px;
}

.sidebar_logo img:hover {
  opacity: .5;
}

.sidebar_bottom {
  position: absolute;
  bottom: 10px;
  padding: 0.5px 0.5px 0.5px 100px;
}

.form-dash {
  width: 35%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.form-dash select {
  background:rgb(255, 255, 255);
    width: 100%;
    padding: 2.5px 2.5px;
    margin: 0;
    box-sizing: border-box;
    border: 5%;
    border-radius: 4px;
    clear: both;
}

.january-form-submit-button {
  background:#e9e5e5;
  color:rgb(0, 0, 0);
  border:.5pxsolid #eee;
  border-radius:20px;
  height:50px;
  width:200px;
  font:22px Verdana, sans-serif;
  box-shadow:5px5px5px #eee;
     text-shadow:none;
}

.january-form-submit-button:hover {
  background:#c0c2c2;
  color:rgb(0, 0, 0);
  border:.5pxsolid #eee;
  border-radius:20px;
  height:50px;
  width:200px;
  font:22px Verdana, sans-serif;
  box-shadow:5px5px5px #eee;
     text-shadow:none;
}

.february-form-submit-button {
  background:#e9e5e5;
  color:rgb(0, 0, 0);
  border:.5pxsolid #eee;
  border-radius:20px;
  height:33px;
  width:100px;
  font:12px Verdana, sans-serif;
  box-shadow:5px5px5px #eee;
     text-shadow:none;
}

.february-form-submit-button:hover {
  background:#c0c2c2;
  color:rgb(0, 0, 0);
  border:.5pxsolid #eee;
  border-radius:20px;
  height:33px;
  width:100px;
  font:12px Verdana, sans-serif;
  box-shadow:5px5px5px #eee;
     text-shadow:none;
}

.march-form-submit-button {
  background:#e9e5e5;
  color:rgb(0, 0, 0);
  border:.5pxsolid #eee;
  border-radius:20px;
  height:25px;
  width:60px;
  font:12px Verdana, sans-serif;
  box-shadow:5px5px5px #eee;
     text-shadow:none;
}

.march-form-submit-button:hover {
  background:#c0c2c2;
  color:rgb(0, 0, 0);
  border:.5pxsolid #eee;
  border-radius:20px;
  height:25px;
  width:60px;
  font:12px Verdana, sans-serif;
  box-shadow:5px5px5px #eee;
     text-shadow:none;
}

.loading-spinner-entry {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  border: 10px solid #ece9e9e5; /* Light grey */
  border-top: 10px solid #2BA880; /* Blue */
  border-radius: 50%;
  animation: spin 0.5s linear infinite;
}

.sidebar-spon-auth {
  align-content: center;
}

.sidebar-auth {
  align-content: center;
}

.sidebar-no-auth {
  align-content: center;
}

.sidebar-center {
  align-content: center;
}